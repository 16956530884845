import React from "react";
import { Trans } from "react-i18next";

export const headingClass = "text-red-500 text-3xl font-semibold uppercase";

const Heading = ({
  heading: H = "h3",
  children,
}: {
  heading?: keyof JSX.IntrinsicElements;
  children: string;
}) => {
  return (
    <H className={`${headingClass} p-4`}>
      <Trans>{children}</Trans>
    </H>
  );
};

export default Heading;
