import React from "react";
import { Link } from "gatsby-plugin-react-i18next";

export const buttonClass = `rounded bg-gradient-to-t from-green-500 to-green-400 text-white px-8 py-2 text-lg shadow-sm tracking-widest`;

const Button = ({
  className = "",
  ...props
}: React.ComponentProps<typeof Link>) => {
  return <Link {...props} className={`${buttonClass} ${className}`} />;
};

export default Button;
