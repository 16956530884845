import React, { Suspense } from "react";
import { graphql, useStaticQuery } from "gatsby";
import { Trans, useTranslation } from "react-i18next";
import { m } from "framer-motion";
import { buttonClass } from "./button";
import Modal from "./modal";
import Form, { Input, Label, Select, Submit, Textarea, Option } from "./forms";
import { Helmet } from "gatsby-plugin-react-i18next";
const PdfReader = React.lazy(() => import("../components/pdf_reader"));

const Job = ({
  role,
  summary,
  file,
}: {
  role: string;
  summary: string;
  file: string;
}) => {
  const [open, setOpen] = React.useState(false);
  const [readMore, setReadMore] = React.useState(false);
  const [applyNow, setApplyNow] = React.useState(false);
  const { i18n } = useTranslation()
  return (
    <li className="">
      <details
        onToggle={() => setOpen((o) => !o)}
        open={open}
        className="border-t border-black-500/20"
      >
        <summary
          className={`active:bg-yellow-300 p-4 ${
            open ? "bg-yellow-500" : ""
          } transition-colors hover:bg-yellow-200`}
        >
          <h4 className="text-red-500 uppercase tracking-wide font-semibold text-2xl cursor-pointer select-none underline flex justify-between items-center">
            <Trans>{role}</Trans>
            <span>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={3}
                stroke="currentColor"
                className="w-6 h-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M8.25 4.5l7.5 7.5-7.5 7.5"
                />
              </svg>
            </span>
          </h4>
        </summary>
        <m.div
          className="p-4"
          animate={
            open
              ? { opacity: 1, translateY: 0 }
              : { opacity: 0, translateY: 10 }
          }
        >
          <Trans>{summary}</Trans>
          <div className="flex justify-between mt-4 py-2">
            <button onClick={() => setReadMore(true)} className={buttonClass}>
              <Trans>Read More</Trans>
            </button>
            <button
              onClick={() => setApplyNow(true)}
              className={`${buttonClass} !from-red-500 !to-red-400`}
            >
              <Trans>Apply Now</Trans>
            </button>
          </div>
        </m.div>
      </details>
      <Modal open={readMore} onClose={() => setReadMore(false)}>
        <Suspense>
          <PdfReader pdf={file.replaceAll("{language}", i18n.language)} />
        </Suspense>
      </Modal>
      <Modal open={applyNow} onClose={() => setApplyNow(false)}>
        <div className="min-h-[100vh] w-screen overflow-y-auto pb-32 bg-white">
          <h2 className="p-4 pb-0 text-2xl text-red-500">
            <Trans>{role}</Trans>
          </h2>
          <JobForm role={role} />
        </div>
      </Modal>
    </li>
  );
};
const JobForm = ({ role }: { role: string }) => {
  return (
    <Form
      emailSubject={`Job Application as ${role}`}
      className="grid"
      formsparkId="FL7C9OhW"
      botpoisonKey="pk_02f943a9-4c2a-43a9-99d7-71785427e37e"
    >
      <input type="hidden" name="role" value={role} />
      <Label htmlFor="name" required>
        Name
      </Label>
      <Input
        type="text"
        id="name"
        name="name"
        placeholder="Type your name"
        required
      />
      <Label htmlFor="lastName" required>
        Last Name
      </Label>
      <Input
        type="text"
        id="lastName"
        name="last_name"
        placeholder="Type your last name"
        required
      />
      <Label htmlFor="gender">Gender</Label>
      <Select name="gender" id="gender">
        <Option>I don't want to say it</Option>
        <Option value="male">Male</Option>
        <Option value="female">Female</Option>
      </Select>
      <Label htmlFor="email" required>
        Email
      </Label>
      <Input
        type="email"
        id="email"
        name="email"
        placeholder="Type your email"
        required
      />
      <Label htmlFor="tel" required>
        Telephone number
      </Label>
      <Input
        type="tel"
        id="tel"
        name="telephone"
        placeholder="e.g. +31(0)6 01010101"
        required
      />
      <Label htmlFor="motivation">Motivation Letter</Label>
      <Textarea id="motivation" name="motivation" placeholder="" />
      <Label htmlFor="cv" required>
        Upload CV
      </Label>
      <p className="bg-green-500 rounded w-fit">
        <input
          key={role}
          type="hidden"
          id="cv"
          name="cv"
          role="uploadcare-uploader"
          data-public-key="915d53f29a0a026fcbbf"
        />
      </p>
      <Submit className="mt-8">Apply as a {role}</Submit>
    </Form>
  );
};

const AllCarrers = () => {
  const {
    allCareerJson: { nodes },
  } = useStaticQuery(query);

  return (
    <ul className="py-4">
      <Helmet>
        <script src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"></script>
      </Helmet>
      {nodes.map(Job)}
    </ul>
  );
};

export default AllCarrers;

const query = graphql`
  query CareersQuery {
    allCareerJson {
      nodes {
        role
        summary
        file
      }
    }
  }
`;
