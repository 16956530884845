import React from "react";
import { graphql, HeadFC } from "gatsby";
import Layout from "../components/layout";
import { Trans } from "react-i18next";
import { HeaderSpacer } from "../components/header";
import Heading from "../components/heading";
import AllCareers from "../components/all_careers";
import SEO from "../seo";
import Staff from "../components/staff";

const Career = () => {
  return (
    <Layout className="snap-proximity">
      <HeaderSpacer />
      <div className="max-w-screen-lg lg:mx-auto">
        <Heading>Join the Panzerò family</Heading>
        <p className="mt-2 text-lg p-4">
          {/* prettier-ignore */}
          <Trans>Come join the Panzerò team for a rewarding career you’ll love! Here at Panzerò, we are committed to providing a fun, fast-casual, and high-quality culinary experience for all of our guests!</Trans>
        </p>
        <Staff />
        <p className="p-4 text-lg">
          {/* prettier-ignore */}
          <Trans>The Panzerò crew is made up of passionate individuals who thrive in an environment with great service, quality food, and tight-knit family culture. We’re a group of motivated, creative, and collaborative people with Panzerò pride!</Trans>
        </p>
        <div
          className="w-full h-64"
          style={{ background: "center / cover url(/career/middle.jpg)" }}
        />
        <p className="p-4 text-lg">
          {/* prettier-ignore */}
          <Trans>We trust and empower our team, so we want to hear from you when you have an idea for us. Our people are our most important assets, and we are dedicated to making sure our team members grow and thrive. Each day brings a chance to learn something new and inspire each other! So roll up your sleeves and achieve more than you thought possible at Panzerò.</Trans>
        </p>
        <AllCareers />
      </div>
    </Layout>
  );
};

export default Career;

export const Head: HeadFC = () => (
  <SEO title="Career">
    <script src="https://unpkg.com/@botpoison/browser" async />
    <script
      src="https://ucarecdn.com/libs/widget/3.x/uploadcare.full.min.js"
      async
    />
  </SEO>
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
