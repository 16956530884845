import React from "react";
import { Trans } from "react-i18next";

export const Input = (props: React.ComponentProps<"input">) => (
  <input
    {...props}
    className="border border-brown-500 mb-1 p-2 outline-none focus:border-x-green-500 focus:border-x-4"
  />
);
export const Label = ({
  children,
  required = false,
  ...props
}: React.ComponentProps<"label"> & { required?: boolean }) => (
  <label {...props} className="text-sm text-brown-500">
    <Trans>{children}</Trans>
    {required && (
      <span className="text-red-500" title="required">
        {" "}
        *
      </span>
    )}
  </label>
);

export const Textarea = (props: React.ComponentProps<"textarea">) => (
  <textarea
    {...props}
    className="snap-start border border-brown-500 mb-2 p-2 outline-none focus:border-green-500  focus:border-x-4"
  ></textarea>
);

export const Select = (props: React.ComponentProps<"select">) => (
  <select {...props} className="px-2 py-3 bg-white border border-brown-500" />
);
export const Option = ({
  children,
  ...props
}: React.ComponentProps<"option">) => (
  <option {...props}>
    <Trans>{children}</Trans>
  </option>
);

export const Submit = ({
  children,
  className = "",
}: {
  children: string | string[];
  className?: string;
}) => (
  <button
    type="submit"
    className={`bg-brown-500 text-white rounded text-lg p-4 my-2 ${className}`}
  >
    <Trans>{children}</Trans>
  </button>
);

const Form = ({
  children,
  emailSubject,
  redirectUrl = "https://www.panzero.nl/success",
  formsparkId,
  botpoisonKey,
  className = "",
  ...props
}: React.ComponentProps<"form"> & {
  emailSubject: string;
  redirectUrl?: string;
  formsparkId: string;
  botpoisonKey: string;
}) => (
  <form
    {...props}
    className={`${className} max-w-screen-lg lg:mx-auto p-4`}
    action={`https://submit-form.com/${formsparkId}`}
    data-botpoison-public-key={botpoisonKey}
  >
    <input type="hidden" name="_redirect" value={redirectUrl} />
    <input type="hidden" name="_email.from" value="Panzerò Notification" />
    <input type="hidden" name="_append" value="false" />
    <input
      type="hidden"
      name="_email.subject"
      value={`🍅 Panzerò Submission: ${emailSubject}`}
    />
    {children}
  </form>
);
export default Form;
