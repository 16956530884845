import React from "react";
import { graphql, useStaticQuery } from "gatsby";

type Employee = {
  name: string;
  src: string;
};

const Picture = ({ name, src }: { name: string; src: string }) => {
  return <img key={name} src={src} title={name} className="w-32" />;
};

const REPEAT = 2;

const Staff = () => {
  const {
    allStaffJson: { nodes },
  } = useStaticQuery<{ allStaffJson: { nodes: Employee[] } }>(query);
  const [top, bottom] = nodes.reduce(
    ([top, bottom], employee, i) =>
      i < nodes.length / 2
        ? [[...top, employee], bottom]
        : [top, [...bottom, employee]],
    [[], []] as Employee[][]
  );
  const [widthTop, widthBottom] = [top.length * 128, bottom.length * 128];
  return (
    <div className="overflow-hidden w-full" style={{ maxWidth: widthBottom }}>
      <div className="" style={{ width: widthTop }}>
        <span className="flex animate-flowRight">
          {Array.from({ length: 2 }).map(() => top.map(Picture))}
        </span>
      </div>
      <div className="" style={{ width: widthBottom }}>
        <span className="flex animate-flowLeft">
          {Array.from({ length: REPEAT }).map(() => bottom.map(Picture))}
        </span>
      </div>
    </div>
  );
};

export default Staff;

const query = graphql`
  query StaffQuery {
    allStaffJson {
      nodes {
        name
        src
      }
    }
  }
`;
