import { AnimatePresence, m } from "framer-motion";
import React from "react";
import panzero from "../images/logo/panzero.png";
import { Backdrop } from "./sidepanel";

export const modalButtonClass =
  "bg-yellow-600/0 active:bg-yellow-600/90 transition-opacity h-8 w-8 p-1 rounded-lg";

const Modal = ({
  open,
  onClose,
  children,
  action,
}: {
  open: boolean;
  onClose?: () => void;
  children: React.ReactNode;
  action?: React.ReactNode;
}) => {
  return (
    <AnimatePresence>
      {open && (
        <m.div
          className="z-50 fixed inset-x-0 top-0 min-h-screen"
          transition={{ ease: "easeInOut" }}
          animate={{ opacity: 1, transform: "scale(1)" }}
          exit={{ opacity: 0, transform: "scale(0.8)" }}
          initial={{ opacity: 0, transform: "scale(0.8)" }}
        >
          <div className="bg-yellow-500 w-screen h-screen">
            <div className="w-full p-4 border-b border-white/80 flex justify-between text-white items-center">
              <img src={panzero} className="h-6 drop-shadow-md" />
              <span className="flex items-center gap-4">
                {action}
                <button
                  title="close modal"
                  onClick={onClose}
                  className={modalButtonClass}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth={5}
                    stroke="currentColor"
                    className="w-6 h-6"
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M6 18L18 6M6 6l12 12"
                    />
                  </svg>
                </button>
              </span>
            </div>
            <div className="bg-yellow-300">{children}</div>
          </div>
        </m.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
